/*.side_bar {*/
/*    background: linear-gradient(180deg,*/
/*    #75efec, #1f8d8c)!important;*/
/*    font-size: 1.4rem !important;*/
/*}*/



/*.side_bar {*/
/*    background: linear-gradient(180deg,*/
/*    #e3ef75, rgba(88, 141, 31, 0.73))!important;*/
/*    font-size: 1.4rem !important;*/
/*}*/


.side_bar {
    background: linear-gradient(180deg,
    #75efec, #1f8d8c)!important;
    font-size: 2rem !important;
}


